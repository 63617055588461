import { useMutation } from '@tanstack/react-query'
import { Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'

import { login, postCaptcha } from '@/services'
import useAppContext from '@/useAppContext'
// import md5 from '@/utils/jsencrypt'

import styles from './styles.module.less'

const InputCode = ({ value, onChange, onSubmit, t, img }) => {
    return (
        <div className={styles.codes}>
            <Input value={value} onChange={onChange} placeholder={t('请输入验证码')} />
            <img onClick={onSubmit} className={styles.img} src={`data:image/gif;base64,${img}`} alt="" />
        </div>
    )
}

const FormItem = ({ dispatch }) => {
    const { t } = useTranslation()
    const [code, setCode] = useState<any>({})
    const mutation = useMutation({
        mutationFn: login,
        onSuccess: (data: any) => {
            const { access_token } = data
            const token = `Bearer ${access_token}`
            localStorage.setItem(window.tokenKey, token)
            dispatch({ token, loading: true })
        },
        onError: (msg: string) => {
            message.error(msg)
        },
    })

    const codeMutation = useMutation({
        mutationFn: postCaptcha,
        onSuccess: (data: any) => {
            setCode(data)
        },
        onError: (msg: string) => {
            console.log(msg)
            message.error(msg)
        },
    })
    useEffect(() => {
        codeMutation.mutate()
    }, [])
    const handleSubmit = () => {
        codeMutation.mutate()
    }

    const handleOk = (values) => {
        // const { password } = values
        // mutation.mutate({ user: email })
        // return
        mutation.mutate({
            ...values,
            // password: md5(password),
            clientId: window.clientId,
            grantType: 'password',
            uuid: code.uuid,
        })
    }

    return (
        <div className={styles.login}>
            <div className={styles.outer}>
                <div className={styles.inline}>
                    <Form
                        layout="vertical"
                        initialValues={{
                            // username: 'admin',
                            // password: 'admin123',
                            // authCode: '2',
                            remember: true,
                        }}
                        onFinish={handleOk}
                        autoComplete="off"
                    >
                        <div className={styles.title}>{t('思铭金融客户管理系统')}</div>
                        <Form.Item
                            label={t('账号')}
                            name="username"
                            // @ts-ignore
                            rules={[{ required: true, message: t('请输入账号') }]}
                        >
                            {/* @ts-ignore */}
                            <Input placeholder={t('请输入账号')} />
                        </Form.Item>
                        <Form.Item
                            label={t('密码')}
                            name="password"
                            // @ts-ignore
                            rules={[{ required: true, message: t('请输入密码') }]}
                        >
                            {/* @ts-ignore */}
                            <Input.Password placeholder={t('请输入密码')} />
                        </Form.Item>
                        <Form.Item
                            label={t('验证码')}
                            name="code"
                            // @ts-ignore
                            rules={[{ required: true, message: t('请输入验证码') }]}
                        >
                            {/* @ts-ignore */}
                            <InputCode onSubmit={handleSubmit} img={code?.img} t={t} />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                loading={mutation.isPending}
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                            >
                                {t('登录')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const Page = () => {
    const { context, dispatch } = useAppContext.getContext()
    const { isLogin } = context
    const navigation = useLocation()
    if (isLogin) {
        const { state } = navigation
        return <Navigate replace to={state || '/'} />
    }
    return <FormItem dispatch={dispatch} />
}

export default Page
