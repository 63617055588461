
export const getMenus = (menus, t) => {
    return menus
        .map((menu) => {
            const { name, path, key, icon, hideInMenu, children = [] } = menu
            if (path == '*' || path == '') {
                return null
            }
            const childrenMenus = getMenus(children, t)
            if (hideInMenu) {
                if (childrenMenus.length > 0) {
                    return childrenMenus
                }
                return null
            }
            if (childrenMenus.length > 0) {
                return {
                    label: t(name),
                    key: key || path,
                    path,
                    icon: icon,
                    children: childrenMenus,
                }
            }
            return {
                label: t(name),
                key: key || path,
                path,
                icon: icon,
            }
        })
        .flat()
        .filter((d) => !!d)
}

