import { HomeOutlined, LockOutlined, SoundOutlined, StarOutlined, UserOutlined } from '@ant-design/icons'
import { importCompont } from '@pn/components/src/utils'
import React from 'react'
import { Navigate } from 'react-router-dom'

import useAppContext from '@/useAppContext'

// import administrator from './Administrator'

const Basics = importCompont(() => import('./Basics'))
const Setting = importCompont(() => import('./Setting'))

const t = (str) => str

const Page404 = () => {
    const { context } = useAppContext.getContext()
    const { loading } = context
    if (loading) {
        return <div />
    }
    return <Navigate replace to={{ pathname: '/' }} />
}

export const routes = [
    {
        path: '/',
        name: t('基础客户管理'),
        icon: <HomeOutlined />,
        element: <Basics key="basics" />,
    },
    {
        path: '/stock',
        name: t('存量客户管理'),
        icon: <UserOutlined />,
        element: <Basics key="stock" type="stock" />,
    },
    {
        path: '/level',
        name: t('星级客户管理'),
        icon: <StarOutlined />,
        element: <Basics key="level" type="level" />,
    },
    {
        path: '/call',
        name: t('已呼客户管理'),
        icon: <SoundOutlined />,
        element: <Basics key="call" type="call" />,
    },
    {
        path: '/password',
        name: t('修改密码'),
        icon: <LockOutlined />,
        element: <Setting />,
    },
    // administrator,
    { path: '*', element: <Page404 /> },
]
