import React, { Suspense } from 'react'

export const importCompont = (importCpt) => {
    const LazyComponent = React.lazy(importCpt)
    const rt = (props) => {
        return (
            <Suspense fallback={null}>
                <LazyComponent {...props} />
            </Suspense>
        )
    }
    return rt
}

export default importCompont
