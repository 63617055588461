import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import useData from './useData';

const cb = async (_) => _

export const usePageQuery = ({
    key,
    getList,
    del = cb,
    update = cb,
    query = {},
    exts = {},
    pageAll = false,
    onSuccess = cb,
    onError = cb,
    ...rest
}) => {
    const { t } = useTranslation();
    const [state, setState] = useData({
        pageSize: 10,
        pageNo: 1,
        selectedRowKeys: [],
        selectedRows: [],
        ...query,
        ext: exts,
    })
    const { pageSize, pageNo, ext, selectedRowKeys, selectedRows } = state;
    const queryClient = useQueryClient();
    const keys = typeof key == "string" ? [key] : key
    const fetchKeys = pageAll ? [...keys, ext] : [...keys, pageNo, pageSize, ext]
    const { data, isFetching, isLoading, isError, isSuccess, error } = useQuery(
        {
            queryKey: fetchKeys,
            queryFn: () => getList({ ...query, ...ext, pageNo, pageSize, pageNum: pageNo }),
            ...rest,
        },
    )

    useEffect(() => {
        if (isSuccess && !isFetching) {
            onSuccess(data)
        }
    }, [isFetching, isSuccess])
    useEffect(() => {
        if (isError && !isFetching) {
            message.error(String(error))
            onError(error)
        }
    }, [isFetching, isError])

    const mutationDel = useMutation({
        mutationFn: (data: any) => del(data),
        onSuccess: () => {
            message.success(t('删除成功'));
            queryClient.invalidateQueries({ queryKey: keys });
        },
        onError: (msg: string) => {
            message.error(msg);
        },
    });
    const mutation = useMutation({
        mutationFn: (data: any) => {
            if (typeof data == 'function') {
                return data()
            }
            return update(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: keys });
            message.success(t('操作成功'));
        },
        onError: (msg: string) => {
            message.error(msg);
        },
    })
    const changePage = (page) => {
        const { current, pageSize } = page;
        // setSearchParams({ pageNo: current });
        setState({ pageNo: current, pageSize, selectedRowKeys: [], selectedRows: [] });
    };

    const list = pageAll ? data || [] : data?.list || data?.rows || []
    const total = pageAll ? list.length : (data?.total || 0)

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => setState({ selectedRowKeys, selectedRows }),
    }
    const tableProps = {
        rowKey: "id",
        bordered: true,
        loading: isFetching,
        dataSource: list,
        pagination: {
            pageSize,
            total,
            current: pageNo,
            showSizeChanger: true,
            hideOnSinglePage: true,
            showTotal: (total) => t(`共x条数据`, { total }),
        },
        onChange: changePage,
    }

    const tableProps2 = {
        rowKey: "id",
        bordered: true,
        loading: isFetching,
        dataSource: list,
        pagination: {
            total,
            showSizeChanger: true,
            hideOnSinglePage: true,
            showTotal: (total) => t(`共x条数据`, { total }),
        },
    }
    return {
        state,
        setState,
        tableProps,
        tableProps2,
        mutation,
        mutationDel,
        queryClient,
        data,
        isLoading,
        isFetching,
        selectedRowKeys,
        selectedRows,
        rowSelection
    }
}
