import fetch from '@/utils/fetch'
// 登录
export const login: any = async (data) => fetch.post("/auth/login", data)

// 注册
export const register: any = async (data) => fetch.post("/v1/noRequired/register", data)

// 发送验证码邮件
export const postCaptcha: any = async () => fetch.get("/auth/code")

// 发送验证码邮件找回密码
export const postCaptcha2: any = async (data) => fetch.post("/v1/noRequired/reset/email", data)

// 找回密码
export const rePassword: any = async (data) => fetch.post("/v1/noRequired/reset/pwd", data)

// 获取用户信息

export const getUserInfo: any = async () => fetch.get("system/user/getInfo")

// 获取首页数据
export const getIndexPanel: any = async () => fetch.get("https://monk.tomlb.com/api/koa/monk/config/index/panel")

export const uploadfile: any = async (file) => {
    let formData = new FormData();
    formData.append("file", file)
    return fetch({
        url: '/biz/user/importData',
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(res => {
        return res[0]
    })
}
