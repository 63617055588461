import { match } from 'path-to-regexp'

export const matchPath = (routes, pathname) => {
    return routes.map(route => {
        let { path, exact = false } = route
        if (path == "*" || path == "") {
            return
        }
        // @ts-ignore
        const regexp = match(path, { end: exact, exact });
        let matchs = regexp(pathname)
        if (matchs) {
            let childrenRoutes = route.children ? matchPath(route.children, pathname) : []
            return [{
                route: route,
                match: matchs,
            }, ...childrenRoutes]
        }
    })
        .flat()
        .filter(d => !!d)
}
